// import { createRequire } from 'module';
import { checkRuntimeInServer } from '../../shared/utils/sever';
import type { ENV_TYPE } from './constants';
import { getEnvConfig } from './constants';
import { SERVER_ENV } from './environment.server';

let env = '' as ENV_TYPE;
// const require = createRequire(import.meta.url);

if (checkRuntimeInServer()) {
  env = SERVER_ENV || 'test';
} else {
  // 客户端使用静态注入的值
  env = __INJECT_ENV__;
}

const envConfig = getEnvConfig(env as ENV_TYPE);

// 被注入运行时的全局配置, 不可被修改
export const GLOBAL_CONFIG = {
  env,
  isProd: env === 'production',
  envConfig: envConfig,
  buildDate: new Date().toUTCString(),
};

export { env as ENV };
