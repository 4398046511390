import { defineNuxtPlugin } from '#imports';
import { getLoggerContext } from '~/modules/winston-logger/runtime/server';

// 错误日志收集
export default defineNuxtPlugin(nuxtApp => {
  if (!process.server) {
    return;
  }

  const { $logger } = useNuxtApp();

  nuxtApp.hook('app:error', error => {
    if ($logger) {
      $logger.error('[App Error]: ', error);
    }
  });

  nuxtApp.hook('vue:error', (error, target, info) => {
    if ($logger) {
      $logger.error('[Nuxt Error => vue:error]: ', error, target, info);
    }
  });
});
