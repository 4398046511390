import BrowserLogger from 'alife-logger';
import { getArmsConfig } from '~/configs/arms/config';
import { GLOBAL_CONFIG } from '~/configs/environment/environment';

export default defineNuxtPlugin(() => {
  const armsConfig = getArmsConfig(GLOBAL_CONFIG.env);

  if (!(!armsConfig || !armsConfig.enabled || !armsConfig.pid || armsConfig.rate === 0)) {
    const rate = Math.random();

    if (armsConfig.rate > rate * 100) {
      BrowserLogger.singleton({
        pid: armsConfig.pid,
        appType: armsConfig.appType,
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        sendResource: armsConfig.sendResource,
        enableLinkTrace: armsConfig.enableLinkTrace,
        behavior: armsConfig.behavior,
        environment: armsConfig.environment,
        release: armsConfig.release,
        useFmp: armsConfig.useFmp,
        enableSPA: armsConfig.enableSPA,
        disableHook: armsConfig.disableHook,
      });
    }
  }
});
