import { NodeIncomingMessage } from 'h3';

export const getHostByReq = (req?: NodeIncomingMessage): string => {
  if (!req) {
    return '';
  }

  const { headers } = req;

  let host = '';

  if (process.dev && headers.referer) {
    host = new URL(headers.referer || '').host;
  }

  host =
    host ||
    headers['x-real-host']?.toString() ||
    headers['x-forwarded-host']?.toString() ||
    headers['host'] ||
    '';

  return host;
};

export const getHostByLocation = () => {
  return window.location.host;
};

export const checkHostInclude = (hosts: string[], target: string) => {
  return hosts.some(host => target.includes(host));
};
