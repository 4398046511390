import EventCenter from '~/shared/services/event';
import { APIEventNameMap } from './constants';
import { useEnvironmentStore } from '~/src/store';
import { GLOBAL_CONFIG } from '~/configs/environment/environment';

export const ApiEventCenter = new EventCenter();

// api 事件总栈管理
const apiEventInit = () => {
  const envStore = useEnvironmentStore();

  // api 相关事件监听，接口错误弹窗提示
  ApiEventCenter.on(APIEventNameMap.API_SERVER_ERROR, error => {
    if (!process.client) {
      return;
    }

    let errorMessage = '';

    if (error?._data?.msg) {
      errorMessage = error._data.msg;
    } else {
      if (error.status === 0) {
        errorMessage = '网络出现异常啦 ~  检查网络后再试下吧！';
      } else if (error.status === 302) {
        errorMessage = '您的登录状态已失效，请重新登录';
      } else {
        errorMessage = '接口报错,状态码：' + error.status;
      }
    }

    if (envStore.isMobile) {
      showFailToast(errorMessage || 'Server Error.');
    } else {
      ElMessage.error(errorMessage || 'Server Error.');
    }
  });

  ApiEventCenter.on(APIEventNameMap.API_UN_LOGIN, error => {
    if (!process.client) {
      return;
    }

    // TODO: 未登录跳login
    console.log('location to login page.');

    // window.location.href = `${GLOBAL_CONFIG.envConfig.homeUrl}/index?reback=${encodeURIComponent(window.location.href)}`;
  });
};

export { apiEventInit };
