import { ArmsConfigMapType } from './types';
const armsPid = '';
export const ArmsConfigMap: ArmsConfigMapType = {
  test: {
    armsPid,
    armsEnvironment: 'daily',
    armsRate: 0,
    armsRelease: 'PC',
  },
  stage: {
    armsPid,
    armsEnvironment: 'gray',
    armsRate: 0,
    armsRelease: 'PC',
  },
  uat: {
    armsPid,
    armsEnvironment: 'pre',
    armsRate: 0,
    armsRelease: 'PC',
  },
  production: {
    armsPid,
    armsEnvironment: 'prod',
    armsRate: 0,
    armsRelease: 'PC',
  },
};
