import { ENV_TYPE } from '../environment/constants';
import { ArmsConfigMap } from './constants';
import { ArmsConfig } from './types';

export function getArmsConfig(env: ENV_TYPE): ArmsConfig {
  const currentEnv = ArmsConfigMap[env];

  return {
    enabled: true,
    pid: currentEnv.armsPid ?? '',
    environment: currentEnv.armsEnvironment ?? 'prod', //prod表示线上环境。gray表示灰度环境。pre表示预发环境。daily表示日常环境。local表示本地环境。
    release: currentEnv.armsRelease ?? '', //应用版本号。建议您配置，便于查看不同版本的上报信息。
    appType: 'web',
    sendResource: true, // 开启页面资源上报
    enableLinkTrace: true, //与应用监控关联
    behavior: true, //开启Console追踪
    useFmp: true, //开启首屏FMP采集
    enableSPA: true, // 开启SPA自动解析
    disableHook: false, //关闭API自动上报
    rate: currentEnv.armsRate ?? 100, //上报率
  };
}
