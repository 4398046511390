<template>
  <NuxtLayout name="default">
    <div class="app-error">
      <img class="error-logo" src="/imgs/server-error.png" alt="网络出错了" />
      <p class="error-tips">网络断开连接了，请稍后重试哦~</p>
    </div>
  </NuxtLayout>
</template>

<script lang="ts">
export default {
  name: 'PageError',
};
</script>

<script lang="ts" setup>
import { useNuxtApp } from '#imports';
/** 此文件将接收所有来自运行时的错误，错误日志收集的重要来源 */

const nuxtApp = useNuxtApp();
interface ErrorType {
  statusMessage: string;
  message: string;
  statusCode: number;
}
// 错误信息，预留占位，后期可根据此进行重定向
const props = defineProps<{
  error: ErrorType;
}>();

console.error('[Page error],', props.error);

if (process.server && nuxtApp.$logger) {
  nuxtApp.$logger.error('[Page Error]', {
    ...props.error,
  });
}
</script>
<style lang="scss">
.app-error {
  display: flex;
  height: calc(100vh - 240px);
  min-height: 633px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-logo {
    width: 300px;
    height: 300px;
  }

  .error-tips {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
