import { GLOBAL_CONFIG } from '../../configs/environment/environment';

export const checkRuntimeInServer = function () {
  if (
    typeof process === 'object' &&
    Object.prototype.toString.call(process) === '[object process]'
  ) {
    return true;
  } else if (typeof window === 'object') {
    return false;
  } else {
    return false;
  }
};

export function isProd() {
  return ['production'].includes(GLOBAL_CONFIG.env);
}
