import unhead_KgADcZ0jPj from "/root/workspace/training_fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/workspace/training_fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/workspace/training_fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/root/workspace/training_fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/workspace/training_fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/workspace/training_fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/root/workspace/training_fe/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_t2GMTTFnMT from "/root/workspace/training_fe/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_tLmAP220nC from "/root/workspace/training_fe/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/root/workspace/training_fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_Init_kv38aTUAih from "/root/workspace/training_fe/src/plugins/00.Init.ts";
import _01_event_hQ6Fzil3Kw from "/root/workspace/training_fe/src/plugins/01.event.ts";
import arms_client_0bZ6UHyNUW from "/root/workspace/training_fe/src/plugins/arms.client.ts";
import error_t9vrANEheK from "/root/workspace/training_fe/src/plugins/error.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_t2GMTTFnMT,
  plugin_tLmAP220nC,
  chunk_reload_client_UciE0i6zes,
  _00_Init_kv38aTUAih,
  _01_event_hQ6Fzil3Kw,
  arms_client_0bZ6UHyNUW,
  error_t9vrANEheK
]