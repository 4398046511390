<template>
  <NuxtLayout class="app-entry">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useEnvironmentStore } from './src/store';

const envStore = useEnvironmentStore();
</script>
