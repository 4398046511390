import { OriginNameMap, MultiHostEnum } from '~/shared/constants/origin';

export const getCityByHost = (host: string) => {
  const cityKey = Object.keys(OriginNameMap).find(key => {
    return host.includes(key);
  }) as keyof typeof OriginNameMap;

  return cityKey ? OriginNameMap[cityKey] : '';
};

// 根据是否是wuyoukaozheng来判断是否隐藏51cto相关
export const isHide51ctoInfo = (host: string) => {
  return host.includes(MultiHostEnum.ZZ_NET) ? 1 : 0;
};
