import { defineStore } from 'pinia';
import { StoreModuleName } from '../../constants';
import { HostConfigMap, HostConfigMapValue } from '~/shared/constants/origin';
import { getHostByReq, getHostByLocation } from '../../../utils/host';
import { isHide51ctoInfo } from '../../../utils/city';

export const useEnvironmentStore = defineStore(StoreModuleName.ENVIRONMENT, () => {
  const nuxtApp = useNuxtApp();
  const $logger = nuxtApp?.$logger;

  // source host
  const host = ref('');
  const hostConfig = ref<HostConfigMapValue>({} as HostConfigMapValue);

  try {
    if (process.server) {
      const req = nuxtApp.ssrContext?.event.req;
      host.value = getHostByReq(req) ?? '';
    } else {
      host.value = getHostByLocation() ?? '';
    }
  } catch (err) {
    console.error(err);
  }
  // source host

  // source host config
  const targetKey = Object.keys(HostConfigMap).find(key =>
    host.value.includes(key)
  ) as keyof typeof HostConfigMap;

  hostConfig.value = HostConfigMap[targetKey] || HostConfigMap.default;

  // source host config

  const device = useDevice();
  // if unset isMobile, default value is undefined. it will reset at client.
  const isMobile = ref(device.isMobile || false);
  const isHide51CTOInfo = ref(isHide51ctoInfo(host.value) || 0);

  const envResult = {
    isMobile,
    device,
    host,
    hostConfig,
    isHide51CTOInfo,
  };

  if ($logger) {
    $logger.info('[pinia environment]', envResult);
  }

  return envResult;
});
