/**
 * 全局环境配置
 * 域名信息，其他信息等按环境预设配置
 */

import { name } from '../../package.json';

const CDN_PROJECT_NAME = name;

export const BASE_ASSETS_DIR_NAME = 'assets';

export type ENV_TYPE = 'test' | 'stage' | 'uat' | 'production';

export interface EvnConfigType {
  // 当前服务主域
  origin: string;
  // api 请求主域
  apiUrl: string;
  // CDN 资源路径
  cdnPublicUrl: string;
  // CDN host
  cdnHost: string;
  // 神策埋点地址
  sensorsServerUrl: string;
  // 题库项目域名
  examUrl: string;
  // edu主站域名
  eduUrl: string;
  // 家园域名
  homeUrl: string;
  // 精培e站点域名
  eUrl: string;
}

export type EnvConfigMapType = Record<ENV_TYPE, EvnConfigType>;

export const GlobalEnvConfigMap: EnvConfigMapType = {
  test: {
    origin: 'https://test-training.51cto.com',
    apiUrl: 'https://test-t.51cto.com',
    cdnHost: 'https://static-test.51cto.com/',
    cdnPublicUrl: `https://static-test.51cto.com/edu/${CDN_PROJECT_NAME}-test/`,
    sensorsServerUrl: 'https://sc.51cto.com/sa?project=default',
    examUrl: 'https://test-t.51cto.com',
    eduUrl: 'https://test-edu.51cto.com',
    homeUrl: 'https://test-home.51cto.com',
    eUrl: 'http://linetraining.51cto.com',
  },
  stage: {
    origin: 'https://stage-training.51cto.com',
    apiUrl: 'https://stage-t.51cto.com',
    cdnHost: 'https://static-test.51cto.com/',
    cdnPublicUrl: `https://static-test.51cto.com/edu/${CDN_PROJECT_NAME}-stage/`,
    sensorsServerUrl: 'https://sc.51cto.com/sa?project=default',
    examUrl: 'https://stage-t.51cto.com',
    eduUrl: 'https://stage-edu.51cto.com',
    homeUrl: 'https://stage-home.51cto.com',
    eUrl: 'https://stage-e.51cto.com',
  },
  uat: {
    origin: 'https://training.51cto.com',
    apiUrl: 'https://t.51cto.com',
    cdnHost: `https://static-test.51cto.com/`,
    cdnPublicUrl: `https://static-test.51cto.com/edu/${CDN_PROJECT_NAME}-uat/`,
    sensorsServerUrl: 'https://sc.51cto.com/sa?project=production',
    examUrl: 'https://t.51cto.com',
    eduUrl: 'https://edu.51cto.com',
    homeUrl: 'https://home.51cto.com',
    eUrl: 'https://e.51cto.com',
  },
  production: {
    origin: 'https://training.51cto.com',
    apiUrl: 'https://t.51cto.com',
    cdnHost: 'https://s5-edu.51cto.com/',
    cdnPublicUrl: `https://s5-edu.51cto.com/edu/${CDN_PROJECT_NAME}/`,
    sensorsServerUrl: 'https://sc.51cto.com/sa?project=production',
    examUrl: 'https://t.51cto.com',
    eduUrl: 'https://edu.51cto.com',
    homeUrl: 'https://home.51cto.com',
    eUrl: 'https://e.51cto.com',
  },
};

export function getEnvConfig(env: ENV_TYPE): EvnConfigType {
  const currentEnv = GlobalEnvConfigMap[env];

  return {
    // 当前服务主域
    origin: currentEnv.origin,
    // api 请求主域
    apiUrl: currentEnv.apiUrl,
    // CDN 资源路径
    cdnPublicUrl: currentEnv.cdnPublicUrl,
    // CDN host
    cdnHost: currentEnv.cdnHost,
    // 神策埋点地址
    sensorsServerUrl: currentEnv.sensorsServerUrl,
    // 题库项目域名
    examUrl: currentEnv.examUrl,
    // edu主站域名
    eduUrl: currentEnv.eduUrl,
    // 家园域名
    homeUrl: currentEnv.homeUrl,
    // 精培e站点域名
    eUrl: currentEnv.eUrl,
  };
}
