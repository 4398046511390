import type { ENV_TYPE } from './constants';
import type { LocalConfigType } from './types';

/* eslint-disable no-empty */
let env = (process.env.ENV || 'test') as ENV_TYPE;

let localConfig = {} as any;

try {
  localConfig = require('./environment.local.ts');
  Object.assign(process.env, localConfig);
  env = localConfig.ENV;
} catch (e) {
  // console.log('err', e);
}

export const SERVER_ENV = env;
export const LocalConfig = localConfig as LocalConfigType;
